import * as React from "react"
import { Seo } from "../../components/seo"
import Layout from '../../components/layout'
import Sidebar from '../../components/sidebar'
import { StaticImage } from "gatsby-plugin-image"

const RequestPickupPage = () => {
  return (
	<Layout>
		<section id="hero">
			<StaticImage src="../../images/hero-images/hero-image-3.jpg" alt="Hero Background" class="heroImage" />
			<div className="heroOverlay"></div>
			<div className="container">
				<h1>Request Pick-Up</h1>
			</div>
		</section>
		<section id="pageContent">
			<div className="container withSidebar">
				<main>
					<iframe title="Request Pick-Up Form" src="https://docs.google.com/forms/d/e/1FAIpQLSeq1NUeZUD5gUakp4X4FuwKyKc-NSVdKNshqUySowdFSKWEOQ/viewform?embedded=true" width="640" height="1900" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
				</main>
				<Sidebar />
			</div>
		</section>
	</Layout>
  )
}
export default RequestPickupPage

export const Head = () => (
	<Seo
        title="Request Pick-Up | Habitat For Humanity Northwoods Wisconsin"
    />
)