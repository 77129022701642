import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Sidebar = ({data}) => {
    return (
        <div className="sidebar">
            <a href="/donate/" className='sidebarCta'>
                <div className="sidebarCtaTitle">Donate Today</div>
                <StaticImage src="../images/partners/partner-2.jpg" alt="Donate" class="sidebarImage" />
            </a>
            <a href="/get-involved/volunteer/" className='sidebarCta'>
                <div className="sidebarCtaTitle">Volunteer</div>
                <StaticImage src="../images/partners/partner-7.jpg" alt="Volunteer" class="sidebarImage" />
            </a>
            <a href="/about/contact/" className='sidebarCta'>
                <div className="sidebarCtaTitle">Sign Up For Our Newsletter</div>
                <StaticImage src="../images/24th-build/IMG_9779.jpg" alt="Newsletter" class="sidebarImage" />
            </a>
        </div>
    )
}
export default Sidebar